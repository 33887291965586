const GET_PATIENT_CLINICS_ACTION = "GET/PATIENT/CLINICS";
const GET_ALL_CLINICS_ACTION = "GET/ALL/CLINICS";
const GET_SINGLE_CLINIC_ACTION = "GET/SINGLE/CLINIC";
const ADD_PATIENT_CLINIC_ACTION = "ADD/PATIENT/CLINIC";
const ADD_PATIENT_ACTION = "ADD/PATIENT";

export const GET_PATIENT_CLINICS_REQUEST = `${GET_PATIENT_CLINICS_ACTION}/REQUEST`;
export const GET_PATIENT_CLINICS_SUCCESS = `${GET_PATIENT_CLINICS_ACTION}/SUCCESS`;
export const GET_PATIENT_CLINICS_ERROR = `${GET_PATIENT_CLINICS_ACTION}/ERROR`;

export const GET_ALL_CLINICS_REQUEST = `${GET_ALL_CLINICS_ACTION}/REQUEST`;
export const GET_ALL_CLINICS_SUCCESS = `${GET_ALL_CLINICS_ACTION}/SUCCESS`;
export const GET_ALL_CLINICS_ERROR = `${GET_ALL_CLINICS_ACTION}/ERROR`;

export const GET_SINGLE_CLINIC_REQUEST = `${GET_SINGLE_CLINIC_ACTION}/REQUEST`;
export const GET_SINGLE_CLINIC_SUCCESS = `${GET_SINGLE_CLINIC_ACTION}/SUCCESS`;
export const GET_SINGLE_CLINIC_ERROR = `${GET_SINGLE_CLINIC_ACTION}/ERROR`;

export const ADD_PATIENT_CLINIC_REQUEST = `${ADD_PATIENT_CLINIC_ACTION}/REQUEST`;
export const ADD_PATIENT_CLINIC_SUCCESS = `${ADD_PATIENT_CLINIC_ACTION}/SUCCESS`;
export const ADD_PATIENT_CLINIC_ERROR = `${ADD_PATIENT_CLINIC_ACTION}/ERROR`;

export const ADD_PATIENT_REQUEST = `${ADD_PATIENT_ACTION}/REQUEST`;
export const ADD_PATIENT_SUCCESS = `${ADD_PATIENT_ACTION}/SUCCESS`;
export const ADD_PATIENT_ERROR = `${ADD_PATIENT_ACTION}/ERROR`;

export function getPatientClinicsRequest(payload) {
  return {
    type: GET_PATIENT_CLINICS_REQUEST,
    payload
  };
}

export function getPatientClinicsSuccess(data) {
  return {
    type: GET_PATIENT_CLINICS_SUCCESS,
    data
  };
}

export function getPatientClinicsError(error) {
  return {
    type: GET_PATIENT_CLINICS_ERROR,
    error
  };
}

export function getSingleClinicRequest(payload) {
  return {
    type: GET_SINGLE_CLINIC_REQUEST,
    payload
  };
}

export function getSingleClinicSuccess(data) {
  return {
    type: GET_SINGLE_CLINIC_SUCCESS,
    data
  };
}

export function getSingleClinicError(error) {
  return {
    type: GET_SINGLE_CLINIC_ERROR,
    error
  };
}

export function getAllClinicsRequest(payload) {
  return {
    type: GET_ALL_CLINICS_REQUEST,
    payload
  };
}

export function getAllClinicsSuccess(data) {
  return {
    type: GET_ALL_CLINICS_SUCCESS,
    data
  };
}

export function getAllClinicsError(error) {
  return {
    type: GET_ALL_CLINICS_ERROR,
    error
  };
}


export function addPatientClinicRequest(payload) {
  return {
    type: ADD_PATIENT_CLINIC_REQUEST,
    payload
  };
}

export function addPatientClinicSuccess(data) {
  return {
    type: ADD_PATIENT_CLINIC_SUCCESS,
    data
  };
}

export function addPatientClinicError(error) {
  return {
    type: ADD_PATIENT_CLINIC_ERROR,
    error
  };
}

export function addPatientRequest(payload) {
  return {
    type: ADD_PATIENT_REQUEST,
    payload
  };
}

export function addPatientSuccess(data) {
  return {
    type: ADD_PATIENT_SUCCESS,
    data
  };
}

export function addPatientError(error) {
  return {
    type: ADD_PATIENT_ERROR,
    error
  };
}