import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import signup from "./signup";
import appointment from "./appointment";
import payment from "./payment";
import clinics from "./clinics";

export default combineReducers({
  auth,
  signup,
  appointment,
  payment,
  clinics,
  form: formReducer
});
