import { Map } from "immutable";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_FP_REQUEST,
  USER_FP_SUCCESS,
  USER_FP_ERROR,
  SET_USER_LOGIN_TITLE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
} from "../actions/auth";

const initialState = Map({
  loginLoading: false,
  loginData: null,
  loginUserDetails: null,
  loginError: null,

  loginTitle: "",
  loginDesc: "",

  fpLoading: false,
  fpData: null,
  fpError: null,

  userLoading: false,
  userData: {},
  userError: null,

  logoutLoading: false,
  logoutSuccess: false,
  logoutError: false,

});

const actionMap = {
  [USER_LOGIN_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        loginLoading: true,
        loginData: null,
        loginUserDetails: null,
        loginError: null,
      })
    );
  },
  [USER_LOGIN_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loginLoading: false,
        loginData: action.data,
        loginUserDetails: action.data?.response?.data[0]?.fieldData,
      })
    );
  },
  [USER_LOGIN_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loginLoading: false,
        loginError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },
  [USER_FP_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        fpLoading: true,
        fpData: null,
        fpError: null,
      })
    );
  },
  [USER_FP_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        fpLoading: false,
        fpData: action.data,
      })
    );
  },
  [USER_FP_ERROR]: (state, action) => {
    return state.merge(
      Map({
        fpLoading: false,
        fpError: action.error && action.error.data ? action.error.data : {},
      })
    );
  },
  [SET_USER_LOGIN_TITLE]: (state, action) => {
    return state.merge(
      Map({
        loginTitle: action.title,
        loginDesc: action.desc,
      })
    );
  },

  [USER_LOGOUT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        logoutLoading: true,
        logoutSuccess: false,
        logoutError: false,
        
      })
    );
  },
  [USER_LOGOUT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        logoutLoading: false,
        logoutSuccess: true,
        logoutError: false,      
      })
    );
  },
  [USER_LOGOUT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        logoutLoading: false,
        logoutSuccess: false,
        logoutError: true,      
      })
    );
  },
};

export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
