import { takeLatest, call, put } from "redux-saga/effects";
import {

  FIND_APPOINTMENT_TYPES_REQUEST,
  findAppointmentTypesSuccess,
  findAppointmentTypesError,

  FIND_DIARY_REQUEST,
  findDiarySuccess,
  findDiaryError,
  
  FIND_DIARY_DAY_REQUEST,
  findDiaryDaySuccess,
  findDiaryDayError,

  FIND_TIME_SLOTS_REQUEST,
  findTimeSlotsSuccess,
  findTimeSlotsError,
  
  FIND_PACKAGES_REQUEST,
  findPackagesSuccess,
  findPackagesError,

  CHECK_TIMESLOTS_REQUEST,
  checkTimeslotsSuccess,
  checkTimeslotsError,
  FIND_CARDS_REQUEST,
  findCardsSuccess,
  findCardsError

} from "../actions/appointment";

import findProductApi from "../api/product-api-find"
import diaryApi from "../api/diary-api-find";
import diaryDayApi from "../api/diary-day-api-find";
import timeSlotsApi from "../api/time-slots-api";
import paymentCardApi from "../api/payment-card-find";
import checkTimeSlotsApi from "../api/check-time-slots-api";
import API_CODES from "../config/api-codes";

function findAppointment() {
  return function* (actions) {
    try {

      const { payload } = actions;
      
      const _data = yield call(() => findProductApi.findAppointments(payload));
      const data = JSON.parse(_data['response']['scriptResult']);
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findAppointmentTypesSuccess(data));
      } else {
        yield put(findAppointmentTypesError(data));
      }
    } catch (error) {
      yield put(findAppointmentTypesError(error));
    }
  };
}

function findDiary() {
  return function* (actions) {
    try {

      const { payload } = actions;

      const _data = yield call(() => diaryApi.findDiary(payload));
      const data = JSON.parse(_data['response']['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findDiarySuccess(data));
      } else {
        yield put(findDiaryError(data));
      }
    } catch (error) {
      yield put(findDiaryError(error));
    }
  };
}

function findDiaryDay() {
  return function* (actions) {
    try {

      const { payload } = actions;
      
      const _data = yield call(() => diaryDayApi.findDiary(payload));
      const data = JSON.parse(_data['response']['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findDiaryDaySuccess(data));
      } else {
        yield put(findDiaryDayError(data));
      }
    } catch (error) {
      yield put(findDiaryDayError(error));
    }
  };
}

function findTimeSlots() {
  return function* (actions) {
    try {

      const { payload } = actions;
      
      const _data = yield call(() => timeSlotsApi.findTimeSlot(payload));
      const data = JSON.parse(_data['response']['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findTimeSlotsSuccess(data));
      } else {
        yield put(findTimeSlotsError(data));
      }
    } catch (error) {
      yield put(findTimeSlotsError(error));
    }
  };
}

function findPackages() {
  return function* (actions) {
    try {

      const { payload } = actions;
      
      const _data = yield call(() => findProductApi.findAppointments(payload));
      const data = JSON.parse(_data['response']['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findPackagesSuccess(data));
      } else {
        yield put(findPackagesError(data));
      }
    } catch (error) {
      yield put(findPackagesError(error));
    }
  };
}

function checkTimeSlots() {
  return function* (actions) {
    try {

      const { payload } = actions;
      
      const data =  yield call(() => checkTimeSlotsApi.checkfindTimeSlots(payload));     

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(checkTimeslotsSuccess(data));
      } else {
        yield put(checkTimeslotsError(data));
      }
    } catch (error) {
      yield put(checkTimeslotsError(error));
    }
  };
}

function findCards() {
  return function* (actions) {
    try {

      const { payload } = actions;
      
      const _data =  yield call(() => paymentCardApi.findPaymentCards(payload));     
      const data = JSON.parse(_data['response']['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findCardsSuccess(data));
      } else {
        yield put(findCardsError(data));
      }
    } catch (error) {
      yield put(findCardsError(error));
    }
  };
}


export function* userAuthWatcher() {
  yield takeLatest(FIND_APPOINTMENT_TYPES_REQUEST, findAppointment());
  yield takeLatest(FIND_DIARY_REQUEST, findDiary());
  yield takeLatest(FIND_DIARY_DAY_REQUEST, findDiaryDay());
  yield takeLatest(FIND_TIME_SLOTS_REQUEST, findTimeSlots());
  yield takeLatest(FIND_PACKAGES_REQUEST, findPackages());
  yield takeLatest(CHECK_TIMESLOTS_REQUEST, checkTimeSlots());
  yield takeLatest(FIND_CARDS_REQUEST, findCards());
}

export default [userAuthWatcher()];
