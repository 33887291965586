import { postRequest } from "./clinics-axios";

const findUri = "/layouts/access/_find";

function findTimeSlot(payload, authKey="") {
  return postRequest(findUri, payload.query, authKey, payload.baseUrl);
}

export default {
  findTimeSlot
};
