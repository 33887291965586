import { takeLatest, call, put } from "redux-saga/effects";

import { 
    
  CREATE_INVOICE_REQUEST, 
  createInvoiceSuccess, 
  createInvoiceError, 

  CREATE_RECEIPT_REQUEST, 
  createReceiptSuccess, 
  createReceiptError, 

  INITIATE_PAYMENT_REQUEST, 
  initiatePaymentSuccess,
  initiatePaymentError,

  CONFIRM_PAYMENT_REQUEST,
  confirmPaymentSuccess,
  confirmPaymentError,

  FIND_RECEIPT_REQUEST,
  findReceiptSuccess,
  findReceiptError,

  GET_CONFORMATION_REQUEST,
  getConformationSuccess,
  getConformationError,

  BOOK_SLOT_FINAL_REQUEST,
  bookFinalSlotSuccess,
  bookFinalSlotError,
  
  BOOK_SLOT_REQUEST,
  bookSlotSuccess,
  bookSlotError,

  ADD_APPOINTMENT_REQUEST,
  addAppointmentSuccess,
  addAppointmentError,

  ADD_PREVIOUS_APPOINTMENT_REQUEST,
  addPreviousAppointmentSuccess,
  addPreviousAppointmentError,

  ADD_INVOICE_REQUEST,
  addInvoiceSuccess,
  addInvoiceError,

  ADD_RECEIPT_REQUEST,
  addReceiptSuccess,
  addReceiptError,

} from "../actions/payment";

import payment_api from "../api/payment-api"
import API_CODES from "../config/api-codes";

function createInvoice() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const data = yield call(() => payment_api.createInvoice(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(createInvoiceSuccess(data));
      } else {
        yield put(createInvoiceError(data));
      } 
    } catch (error) {
      yield put(createInvoiceError(error));
    }
  } 
}

function createReceipt() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const data = yield call(() => payment_api.createReceipt(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(createReceiptSuccess(data));
      } else {
        yield put(createReceiptError(data));
      } 
    } catch (error) {
      yield put(createReceiptError(error));
    }
  } 
}

function initiatePayment() {
  return function* (actions) {
    try {
      const { payload } = actions;
      
      const data = yield call(() => payment_api.initiatePayment(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(initiatePaymentSuccess(data));
      } else {
        yield put(initiatePaymentError(data));
      } 
    } catch (error) {
      yield put(initiatePaymentError(error));
    }
  } 
}

function confirmPayment() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const data = yield call(() => payment_api.confirmPayment(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(confirmPaymentSuccess(data));
      } else {
        yield put(confirmPaymentError(data));
      } 
    } catch (error) {
      yield put(confirmPaymentError(error));
    }
  } 
}

function findReceipt() {
  return function* (actions) {
    try {
      const { payload } = actions

      const _data = yield call(() => payment_api.findReceipt(payload));
      const data = JSON.parse(_data?.['response']?.['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(findReceiptSuccess(data));
      } else {
        yield put(findReceiptError(data));
      } 
    } catch (error) {
      yield put(findReceiptError(error));
    }
  } 
}

function getConfirmation() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _data = yield call(() => payment_api.getConfirmation(payload));
      const data = JSON.parse(_data?.['response']?.['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getConformationSuccess(data));
      } else {
        yield put(getConformationError(data));
      } 
    } catch (error) {
      yield put(getConformationError(error));
    }
  } 
}

function bookFinalSlots() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const data = yield call(() => payment_api.bookFinalSlot(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(bookFinalSlotSuccess(data));
      } else {
        yield put(bookFinalSlotError(data));
      } 
    } catch (error) {
      yield put(bookFinalSlotError(error));
    }
  } 
}

function bookSlots() {
  return function* (actions) {
    try {
      const { payload } = actions;
    
      const data = yield call(() => payment_api.bookSlot(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(bookSlotSuccess(data));
      } else {
        yield put(bookSlotError(data));
      } 
    } catch (error) {
      yield put(bookSlotError(error));
    }
  } 
}

function addAppointment() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => payment_api.addAppointment(payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addAppointmentSuccess(data));
      } else {
        yield put(addAppointmentError(data));
      }
    } catch (error) {
      yield put(addAppointmentError(error));
    }
  };
}


function addPreviousAppointment(){
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => payment_api.addAppointment(payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addPreviousAppointmentSuccess(data));
      } else {
        yield put(addPreviousAppointmentError(data));
      }
    } catch (error) {
      yield put(addPreviousAppointmentError(error));
    }
  };
}


function addInvoice() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => payment_api.addInvoice(payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addInvoiceSuccess(data));
      } else {
        yield put(addInvoiceError(data));
      }
    } catch (error) {
      yield put(addInvoiceError(error));
    }
  };
}

function addReceipt() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => payment_api.addReceipt(payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addReceiptSuccess(data));
      } else {
        yield put(addReceiptError(data));
      }
    } catch (error) {
      yield put(addReceiptError(error));
    }
  };
}

export function* profileAuthWatcher() {
  yield takeLatest(CREATE_INVOICE_REQUEST, createInvoice());
  yield takeLatest(CREATE_RECEIPT_REQUEST, createReceipt());
  yield takeLatest(INITIATE_PAYMENT_REQUEST, initiatePayment());
  yield takeLatest(CONFIRM_PAYMENT_REQUEST, confirmPayment());
  yield takeLatest(FIND_RECEIPT_REQUEST, findReceipt());
  yield takeLatest(GET_CONFORMATION_REQUEST, getConfirmation());
  yield takeLatest(BOOK_SLOT_FINAL_REQUEST, bookFinalSlots());
  yield takeLatest(BOOK_SLOT_REQUEST, bookSlots());
  yield takeLatest(ADD_APPOINTMENT_REQUEST, addAppointment());
  yield takeLatest(ADD_PREVIOUS_APPOINTMENT_REQUEST, addPreviousAppointment());
  yield takeLatest(ADD_INVOICE_REQUEST, addInvoice());
  yield takeLatest(ADD_RECEIPT_REQUEST, addReceipt());
}

export default [profileAuthWatcher()];