import React, { Component } from 'react';
import Logo from "../../../assets/images/orange-blue.png"

class Footer extends Component {

  render(){
    return (
      <footer>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-12">
                      <img src={Logo} />
                      <p>Powered by BookmyClinic</p>
                  </div>
              </div>
          </div>
      </footer>
  );
  }
  
};

export default Footer;