import React, { Fragment, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import { routes } from "./config/routes";
import AuthRouter from "./routers/AuthRouter";
import NoAuthRouter from "./routers/NoAuthRouter";
import { ToastContainer } from "react-toastify";

const Home = lazy(() => import("./views/home/Home"));
const Login = lazy(() => import("./views/login/Login"));
const Register = lazy(() => import("./views/register/Register"));
const ForgotPassword = lazy(() =>
  import("./views/forgot-password/ForgotPassword")
);
const NotFound = lazy(() => import("./views/not-found/NotFound"));

function App() {
  return (
    <Fragment>
      <Suspense fallback={<div>Loading... </div>}>
        <Router>
          <Switch>
            <AuthRouter path={routes.BASEPATH} exact>
              <Home />
            </AuthRouter>
            <NoAuthRouter path={routes.LOGIN}>
              <Login />
            </NoAuthRouter>
            <NoAuthRouter path={routes.REGISTER}>
              <Register />
            </NoAuthRouter>
            <NoAuthRouter path={routes.FORGOT_PASSWORD}>
              <ForgotPassword />
            </NoAuthRouter>
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
      <ToastContainer autoClose={3000} />
    </Fragment>
  );
}

export default App;
