import { Map } from "immutable";

import {
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_ERROR,
  USER_SIGNUP_EMAIL_DUPLICATE
} from "../actions/signup";

import { EMAIL_ALREADY_IN_USE } from "../config/labels";

const initialState = Map({
  signUpLoading: false,
  signUpData: null,
  signUpError: null,

});

const actionMap = {
  [USER_SIGNUP_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        signUpLoading: true,
        signUpData: null,
        signUpError: null
      })
    );
  },
  [USER_SIGNUP_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        signUpLoading: false,
        signUpData: action.data
      })
    );
  },
  [USER_SIGNUP_ERROR]: (state, action) => {
    return state.merge(
      Map({
        signUpLoading: false,
        signUpError:
        action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!"
      })
    );
  },
  [USER_SIGNUP_EMAIL_DUPLICATE]: (state, action) => {
    return state.merge(
      Map({
        signUpLoading: false,
        signUpError: EMAIL_ALREADY_IN_USE
      })
    );
  }

};

export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
