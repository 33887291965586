import axios from "axios";
import API_CODES from "../config/api-codes";
import {
  BAD_REQUEST,
  UNAUTHORIZED_STATUS,
  TOKEN_LOCAL_STORAGE_KEY,
} from "../config/constants";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if ((
      error.response.status === UNAUTHORIZED_STATUS &&
      error.response.data.messages &&
      error.response.data.messages[0] &&
      error.response.data.messages[0].code &&
      error.response.data.messages[0].code === API_CODES.INVALID_TOKEN
    ) ||
    (
      error.response.status === BAD_REQUEST &&
      error.response.data.messages &&
      error.response.data.messages[0] &&
      error.response.data.messages[0].code &&
      error.response.data.messages[0].code === API_CODES.TOKEN_NOT_FOUND
    )) {

      return getAuthorizedToken()
        .then((token) => {
          const { config } = error;
          localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
          config.headers["Authorization"] = `Bearer ${token}`;

          if(JSON.parse(config['data'])['logout'] === true){
            config['url'] = process.env.REACT_APP_SERVER_BASE_URL + '/sessions/' + token;
          }

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          });
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);

function apiError(status, message, _data) {
  let data = null;
  let isObject = false;
  try {
    data = JSON.parse(_data);
    isObject = true;
  } catch (error) {
    data = _data;
  }
  return {
    status,
    message,
    data,
    toString: () => {
      return `${message}\nResponse:\n${
        isObject ? JSON.stringify(data, null, 2) : data
      }`;
    },
  };
}

export function getRequest(
  _url,
  authToken = "",
  queryParams = {},
  BaseUrl = process.env.REACT_APP_SERVER_BASE_URL,
  _headers = {}
) {
  const url = `${BaseUrl}${_url}`;
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  } else if (localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)) {
    headers.Authorization = `Bearer ${localStorage.getItem(
      TOKEN_LOCAL_STORAGE_KEY
    )}`;
  }
  return axios({
    method: "GET",
    url,
    headers: { ...headers, ..._headers },
    params: queryParams,
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        throw apiError(error.response.status, `Request failed.`, error);
      } else if (error.request) {
        throw apiError(error.request.status, `Request failed.`, error);
      } else {
        throw apiError("ERROR", error.toString(), error);
      }
    });
}

export function postRequest(
  _url,
  data,
  authToken = "",
  BaseUrl = process.env.REACT_APP_SERVER_BASE_URL,
  _headers = {}
) {
  const url = `${BaseUrl}${_url}`;
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  } else if (localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)) {
    headers.Authorization = `Bearer ${localStorage.getItem(
      TOKEN_LOCAL_STORAGE_KEY
    )}`;
  }

  return axios({
    method: "POST",
    url,
    data,
    headers: { ...headers, ..._headers },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        throw apiError(error.response.status, `Request failed.`, error);
      } else if (error.request) {
        throw apiError(error.request.status, `Request failed.`, error);
      } else {
        throw apiError("ERROR", error.toString(), error);
      }
    });
}

export function patchRequest(
  _url,
  data,
  authToken = "",
  BaseUrl = process.env.REACT_APP_SERVER_BASE_URL,
  _headers = {}
) {
  const url = `${BaseUrl}${_url}`;
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  } else if (localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)) {
    headers.Authorization = `Bearer ${localStorage.getItem(
      TOKEN_LOCAL_STORAGE_KEY
    )}`;
  }
  return axios({
    method: "PATCH",
    url,
    data,
    headers: { ...headers, ..._headers },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        throw apiError(error.response.status, `Request failed.`, error);
      } else if (error.request) {
        throw apiError(error.request.status, `Request failed.`, error);
      } else {
        throw apiError("ERROR", error.toString(), error);
      }
    });
}

export function putRequest(
  _url,
  data,
  authToken = "",
  BaseUrl = process.env.REACT_APP_SERVER_BASE_URL,
  _headers = {}
) {
  const url = `${BaseUrl}${_url}`;
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  } else if (localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)) {
    headers.Authorization = `Bearer ${localStorage.getItem(
      TOKEN_LOCAL_STORAGE_KEY
    )}`;
  }
  return axios({
    method: "PUT",
    url,
    data,
    headers: { ...headers, ..._headers },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        throw apiError(error.response.status, `Request failed.`, error);
      } else if (error.request) {
        throw apiError(error.request.status, `Request failed.`, error);
      } else {
        throw apiError("ERROR", error.toString(), error);
      }
    });
}

export function getAuthorizedToken(BaseUrl = process.env.REACT_APP_SERVER_BASE_URL) {

  const url = `${BaseUrl}/sessions`;
  const credentails = btoa(
    `${process.env.REACT_APP_API_AUTH_TOKEN_USERNAME}:${process.env.REACT_APP_API_AUTH_TOKEN_PASSWORD}`
  );
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Basic ${credentails}`,
  };
  return axios({
    method: "POST",
    url,
    data: {},
    headers,
  })
    .then(function (response) {
      return response.data.response.token;
    })
    .catch(function (error) {
      if (error.response) {
        throw apiError(error.response.status, `Request failed.`, error);
      } else if (error.request) {
        throw apiError(error.request.status, `Request failed.`, error);
      } else {
        throw apiError("ERROR", error.toString(), error);
      }
    });
}

export function deleteRequest(
  _url,
  authToken = "",
  BaseUrl = process.env.REACT_APP_SERVER_BASE_URL,
  _headers = {}
) {
  const url = `${BaseUrl}${_url}`;
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  } else if (localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)) {
    headers.Authorization = `Bearer ${localStorage.getItem(
      TOKEN_LOCAL_STORAGE_KEY
    )}`;
  }
  return axios({
    method: "DELETE",
    url,
    headers: { ...headers, ..._headers },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        throw apiError(error.response.status, `Request failed.`, error);
      } else if (error.request) {
        throw apiError(error.request.status, `Request failed.`, error);
      } else {
        throw apiError("ERROR", error.toString(), error);
      }
    });
}
