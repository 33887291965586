export const BASE_URL = "/";
export const routes = {
  BASEPATH: BASE_URL,

  LOGIN: `${BASE_URL}login`,

  REGISTER: `${BASE_URL}register`,

  FORGOT_PASSWORD: `${BASE_URL}forgot-password`
};
