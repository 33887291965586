const USER_LOGIN_ACTION = "USER/LOGIN";
const USER_FP_ACTION = "USER/FP";
const USER_LOGOUT_ACTION = "USER/LOGOUT";

export const USER_LOGIN_REQUEST = `${USER_LOGIN_ACTION}/REQUEST`;
export const USER_LOGIN_SUCCESS = `${USER_LOGIN_ACTION}/SUCCESS`;
export const USER_LOGIN_ERROR = `${USER_LOGIN_ACTION}/ERROR`;

export const USER_FP_REQUEST = `${USER_FP_ACTION}/REQUEST`;
export const USER_FP_SUCCESS = `${USER_FP_ACTION}/SUCCESS`;
export const USER_FP_ERROR = `${USER_FP_ACTION}/ERROR`;

export const USER_LOGOUT_REQUEST = `${USER_LOGOUT_ACTION}/REQUEST`;
export const USER_LOGOUT_SUCCESS = `${USER_LOGOUT_ACTION}/SUCCESS`;
export const USER_LOGOUT_ERROR = `${USER_LOGOUT_ACTION}/ERROR`;

export const SET_USER_LOGIN_TITLE = "USER/LOGIN_SET_MESSAGE";

export function userLoginRequest(payload) {
  return {
    type: USER_LOGIN_REQUEST,
    payload,
  };
}

export function userLoginSuccess(data) {
  return {
    type: USER_LOGIN_SUCCESS,
    data,
  };
}

export function userLoginError(error) {
  return {
    type: USER_LOGIN_ERROR,
    error,
  };
}

export function userFpRequest(payload) {
  return {
    type: USER_FP_REQUEST,
    payload,
  };
}

export function userFpSuccess(data) {
  return {
    type: USER_FP_SUCCESS,
    data,
  };
}

export function userFpError(error) {
  return {
    type: USER_FP_ERROR,
    error,
  };
}

export function setUserLoginTitle(title, desc) {
  return {
    type: SET_USER_LOGIN_TITLE,
    title,
    desc,
  };
}

export function userLogoutRequest(payload) {
  return {
    type: USER_LOGOUT_REQUEST,
    payload,
  };
}

export function userLogoutSuccess(data) {
  return {
    type: USER_LOGOUT_SUCCESS,
    data,
  };
}

export function userLogoutError(error) {
  return {
    type: USER_LOGOUT_ERROR,
    error,
  };
}
