export const INVALID_EMAIL_OR_PASSWORD = "Invalid email or password.";
export const SOMETHING_WENT_WRONG = "Something went wrong!";
export const FORGOT_PASSWORD_SUCCESS = "Password Link has been sent";
export const FORGOT_PASSWORD_EMAIL_NOT_FOUND = "Email doesn't exists";
export const EMAIL_ALREADY_IN_USE = "This email is already in use."
export const CONGRATULATIONS = "Congratulations";
export const NO_DATA_FOUND_FROM_POSTCODE =
  "No data was found for the requested postal code";
export const CONGRATULATIONS_DESC =
  "Your registration has been successful! <br /> Login to book your first treatment.";
export const ACCESS_FORBIDDEN = "Access Forbidden! Please try again later.";
export const SESSION_TIMEOUT = "Your session has timed out";
