import { takeLatest, call, put } from "redux-saga/effects";
import {

  GET_PATIENT_CLINICS_REQUEST,
  getPatientClinicsSuccess,
  getPatientClinicsError,

  GET_SINGLE_CLINIC_REQUEST,
  getSingleClinicSuccess,
  getSingleClinicError,

  ADD_PATIENT_CLINIC_REQUEST,
  addPatientClinicSuccess,
  addPatientClinicError,

  ADD_PATIENT_REQUEST,
  addPatientSuccess,
  addPatientError,

  GET_ALL_CLINICS_REQUEST,
  getAllClinicsSuccess,
  getAllClinicsError,

}  from "../actions/clinics";

import findApi from "../api/clinic-api-find";
import API_CODES from "../config/api-codes";
import { LOGGED_USER_SESSION_KEY } from "../config/constants";

function getPatientsClinics() {
  return function* (actions) {
    try {
      const _payload = {
        query: [{ "z1": "1" }],
        "script" : "getPatientClinics",
        "script.param" : localStorage.getItem(LOGGED_USER_SESSION_KEY)
      };

      const data = yield call(() => findApi.getPatientClinics(_payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getPatientClinicsSuccess(JSON.parse(data?.['response']?.['scriptResult'])));
      } else {
        yield put(getPatientClinicsError(JSON.parse(data?.['response']?.['scriptResult'])));
      }
    } catch (error) {
      yield put(getPatientClinicsError(error));
    }
  };
}

function getSingleClinic() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
        "script" : "getClinic",
        "script.param" : payload
      }      
      const _data = yield call(() => findApi.getSingleClinic(_payload));
      const data = JSON.parse(_data?.['response']?.['scriptResult']);

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getSingleClinicSuccess(data));
      } else {
        yield put(getSingleClinicError(data));
      }
    } catch (error) {
      yield put(getSingleClinicError(error));
    }
  };
}

function addPatientClinic() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => findApi.addPatientClinic(payload) );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addPatientClinicSuccess(data));
      } else {
        yield put(getSingleClinicError(data));
      }
    } catch (error) {
      yield put(addPatientClinicError(error));
    }
  };
}

function addPatientRequest() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
      
        "script" : "createPatient",
        "script.param" : `${payload["payload"]["nameFirst"]}|${payload["payload"]["nameLast"]}|${payload["payload"]["email"]}|${payload["payload"]["phoneMobile"]}|${payload["payload"]["dateOfBirth"]}|${payload["payload"]["address1"]}|${payload["payload"]["address2"]}|${payload["payload"]["addressTown"]}|${payload["payload"]["addressCounty"]}|${payload["payload"]["addressPostcode"]}|${payload["payload"]["idPatient"]}|${payload["payload"]["ID_Clinic"]}`
      }

      const combineData = { payload: _payload, baseUrl: payload.baseUrl }

      const data = yield call(() => findApi.addPatient(combineData));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        const {
          response: { scriptResult },
        } = data;
        const {
          payload: { idPatient, idClinic },
        } = payload;
        const ID_Patient = scriptResult;

        const combinedPatientPayload = {
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "updatePatientID",
          "script.param" : `${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${idClinic}|${ID_Patient}`
        }

        const patientClinicAddPatientRes = yield call(() => findApi.patientClinicAddPatient(combinedPatientPayload));

        if (
          patientClinicAddPatientRes &&
          patientClinicAddPatientRes.messages &&
          patientClinicAddPatientRes.messages[0] &&
          patientClinicAddPatientRes.messages[0].code === API_CODES.SUCCESS
        ) {
          yield put(addPatientSuccess(data));
        } else {
          yield put(addPatientError(data));
        }
      } else {
        yield put(addPatientError(data));
      }
    } catch (error) {
      yield put(addPatientError(error));
    }
  };
}

function getAllClinics() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const _payload = {
        query: [{ "z1": "1" }],
        "script" : "clinicWildcard",
        "script.param" : `${payload.sessionId}|${payload.search || '*'}`
      };
      const _data = yield call(() => findApi.getAllClinics(_payload));
      const data = JSON.parse(_data['response']['scriptResult']);
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getAllClinicsSuccess(data));
      } else {
        yield put(getAllClinicsError(data));
      }
    } catch (error) {
      yield put(getAllClinicsError(error));
    }
  };
}


export function* profileAuthWatcher() {
  yield takeLatest(GET_PATIENT_CLINICS_REQUEST, getPatientsClinics());
  yield takeLatest(GET_SINGLE_CLINIC_REQUEST, getSingleClinic());
  yield takeLatest(ADD_PATIENT_CLINIC_REQUEST, addPatientClinic());
  yield takeLatest(ADD_PATIENT_REQUEST, addPatientRequest());
  yield takeLatest(GET_ALL_CLINICS_REQUEST, getAllClinics());
}
  
export default [profileAuthWatcher()];
  