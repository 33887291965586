import React, { Component } from 'react';

import { routes } from '../../../config/routes';
import Logo from '../../../assets/images/logo.png';
import { LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY, TOKEN_LOCAL_STORAGE_KEY, CLINIC_TOKEN_LOCAL_STORAGE_KEY, LOGGED_USER_SESSION_KEY, CLINIC_SESSION_KEY } from '../../../config/constants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogoutRequest } from '../../../actions/auth';
import { SOMETHING_WENT_WRONG } from '../../../config/labels';
import { Toast } from '../../../helpers/toast';

class Header extends Component {

  componentDidUpdate(prevProps) {
    const {

      logoutLoading,
      logoutSuccess
      
    } = this.props;

    if (!logoutLoading && prevProps.logoutLoading !== logoutLoading) {
      if (logoutSuccess) {
        localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(CLINIC_TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY);
        localStorage.removeItem(LOGGED_USER_SESSION_KEY);
        localStorage.removeItem(CLINIC_SESSION_KEY);
        this.props.history.push(routes.LOGIN);        
      } else {
        Toast({ type: 'error', message: SOMETHING_WENT_WRONG });
      }
    }

  }


  render(){
    return (
    <div id="header" className="header">
      <nav className={`navbar navbar-expand-md navbar-dark fixed-top ${ localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY) ? 'after-login' : 'before-login'}`}>
      { localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY) ? <button className="cmn-btn white-cmn-btn" onClick={() => this.handleLogout()}>Logout</button> : null}
        <div className="container">
          <a className="navbar-brand" href="# "><img src={Logo} alt="" /></a>
        </div>
      </nav>
    </div>
  );
  }
  
  handleLogout = () => {
    this.props.dispatch(userLogoutRequest(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)));
  }

};

const mapStateToProps = (state) => {

  const { auth } = state;

  return {

    logoutLoading: auth.get("logoutLoading"),
    logoutSuccess: auth.get("logoutSuccess"),
    logoutError: auth.get("logoutError"),

  }
}


Header = withRouter(Header);
export default connect(mapStateToProps)(Header);
