import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Toast = props => {
  return toast[props.type](props.message, {
    position: toast.POSITION.TOP_RIGHT,
    closeButton: true,
    hideProgressBar: true,
    closeOnClick: true,
    rtl: false
  });
};
