import { Map } from "immutable";

import {

  GET_PATIENT_CLINICS_REQUEST,
  GET_PATIENT_CLINICS_SUCCESS,
  GET_PATIENT_CLINICS_ERROR,

  GET_SINGLE_CLINIC_REQUEST,
  GET_SINGLE_CLINIC_SUCCESS,
  GET_SINGLE_CLINIC_ERROR,

  ADD_PATIENT_CLINIC_REQUEST,
  ADD_PATIENT_CLINIC_SUCCESS,
  ADD_PATIENT_CLINIC_ERROR,

  ADD_PATIENT_REQUEST,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_ERROR,

  GET_ALL_CLINICS_REQUEST,
  GET_ALL_CLINICS_SUCCESS,
  GET_ALL_CLINICS_ERROR,

} from "../actions/clinics";

import { SOMETHING_WENT_WRONG } from "../config/labels";

const initialState = Map({
  
  patientClinicLoading: false,
  patientClinicData: {},
  patientClinics: [],
  patientClinicsCardsInfo: {},
  patientClinicDeleteCard: {
    clinicRecordId: 0,
    ID_PaymentCard: 0,
    cardNumber: '',
    baseUrl: '',
    loading: false,
    deleteStatus: false,
    error: null
  },
  patientClinicError: null,

  singleClinicLoading: false,
  singleClinicData: {},
  singleClinicError: null,

  addClinicLoading: false,
  addClinicData: {},
  addClinicError: null,
  
  addPatientLoading: false,
  addPatientData: null,
  addPatientError: null

})



const actionMap = {

  [GET_PATIENT_CLINICS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        patientClinicLoading: true,
        patientClinicData: {},
        patientClinics: [],
        patientClinicError: null
      })
    );
  },
  [GET_PATIENT_CLINICS_SUCCESS]: (state, action) => {
    const patientClinicsCardsInfo = addCardsInfoToPatientClinics(action.data['response']['data']);
    return state.merge(
      Map({
        patientClinicLoading: false,
        patientClinics: action.data['response']['data'],
        patientClinicsCardsInfo,
        patientClinicData: action.data,
      })
    );
  },
  [GET_PATIENT_CLINICS_ERROR]: (state, action) => {

    return state.merge(
      Map({
        patientClinicLoading: false,
        patientClinicData: {},
        patientClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [GET_SINGLE_CLINIC_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        singleClinicLoading: true,
        singleClinicData: {}
      })
    );
  },
  [GET_SINGLE_CLINIC_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        singleClinicLoading: false,
        singleClinicData: action.data['response']['data'][0]['fieldData'],
      })
    );
  },
  [GET_SINGLE_CLINIC_ERROR]: (state, action) => {
    return state.merge(
      Map({
        singleClinicLoading: false,
        singleClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [ADD_PATIENT_CLINIC_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addClinicLoading: true,
        addClinicData: {},
      })
    );
  },
  [ADD_PATIENT_CLINIC_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addClinicLoading: false,
        addClinicData: action.data,
      })
    );
  },
  [ADD_PATIENT_CLINIC_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addClinicLoading: false,
        addClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [ADD_PATIENT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addPatientLoading: true,
        addPatientData: null,
        addPatientError: null
      })
    );
  },
  [ADD_PATIENT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addPatientLoading: false,
        addPatientData: action.data,
      })
    );
  },
  [ADD_PATIENT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addPatientLoading: false,
        addPatientData: null,
        addPatientError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [GET_ALL_CLINICS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        allClinicLoading: true,
        allClinicData: null,
        allClinics: [],
        allClinicError: null
      })
    );
  },
  [GET_ALL_CLINICS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        allClinicLoading: false,
        allClinics: action.data['response']['data'],
        allClinicData: action.data,
      })
    );
  },

  [GET_ALL_CLINICS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        allClinicLoading: false,
        allClinicData: null,
        allClinics: [],
        allClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

}

function addCardsInfoToPatientClinics(patientClinics) {
  const cardInfo = {};
  patientClinics.map(o => {
    cardInfo[o.recordId] = {
      isOpen: false, loading:false, cards: [], error:null
    }
    return o;
  });
  return cardInfo;
}

export default function reducer(state = initialState, action) {
    const fn = actionMap[action.type];
    return fn ? fn(state, action) : state;
  }
  