import { postRequest } from "./clinics-axios";

const findUri = "/layouts/access/_find";

function findDiary(payload, authKey="") {
  return postRequest(findUri, payload.query, authKey, payload.baseUrl);
}

export default {
  findDiary
};
