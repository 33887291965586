import { all } from "redux-saga/effects";
import auth from "./auth";
import signup from "./signup";
import appointment from "./appointment";
import payment from "./payment";
import clinics from "./clinics";

export default function* rootSaga() {
  yield all([...auth, ...signup, ...appointment, ...payment, ...clinics]);
}
