import React from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../views/templates/no-auth-router/Header";
import Footer from "../views/templates/no-auth-router/Footer";
import { LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY } from "../config/constants";
import { routes } from "../config/routes";

const NoAuthRouter = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY)) {
          return (
            <div className="main-container">
              <div className="inner-container">
                <Header />
                {children}
                <Footer />
              </div>
            </div>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: routes.BASEPATH,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default NoAuthRouter;
