import { getRequest, postRequest } from ".";
import { postRequest as clinicPostRequest } from "./clinics-axios";

const findUri = '/layouts/access/_find';

function getPatientClinics(payload, authKey="") {
  return postRequest(findUri, payload, authKey);
}

function getSingleClinic(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

function addPatientClinic(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

function addPatient(payload, authKey = "") {
  return clinicPostRequest( 
    findUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function patientClinicAddPatient(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}


function getAllClinics(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

export default {
  getAllClinics,
  getPatientClinics,
  getSingleClinic,
  addPatientClinic,
  addPatient,
  patientClinicAddPatient
};
