
export const ADDRESS_FETCH_SERVER_RESPONSE_TYPE = "data_formatted";
export const CLINICS_REDIRECTION_URL = "https://fleetstreet.digitusrain.co.uk/";
export const BAD_REQUEST = 400;
export const UNAUTHORIZED_STATUS = 401;
export const COMPONENT_DELAY = 300;
export const COMPONENT_TIMEOUT = 4000;

export const DEFAULT_DATE_FORMAT = "dd/MM/yyyy";

export const LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY = "user-details";
export const REMEMBER_ME_LOCAL_STORAGE_KEY = "remember-me";
export const TOKEN_LOCAL_STORAGE_KEY = "access-token";
export const CLINIC_TOKEN_LOCAL_STORAGE_KEY = "clinic-access-token";
export const LOGGED_USER_SESSION_KEY = "session-key";
export const CLINIC_SESSION_KEY = "clinic-session-key";
export const CLINIC_BASE_URL_HEADER_KEY = "clinic-base-url";
export const CARD_APPROVED = "Approved";

export const DAY_LIST = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTH_LIST = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];