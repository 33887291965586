import { postRequest } from ".";

const findUri = "/layouts/access/_find";

function userSignUp(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

function forgotPassword(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

export default {
  userSignUp,
  forgotPassword,
};
